export const HEADER_HEADING = 'ACG';
export const USERID_REQUIRED = 'User ID is required';
export const PASSWORD_REQUIRED = 'Password is required.';
export const USERID_INVALID = 'User ID is not valid';
export const USERID_MINLENGTH = 'user ID should be at-least 8 characters.';
export const PASSWORD_MINLENGTH = 'Password should be at-least 8 characters.';
export const LOGIN = 'Login';
export const USERID = 'User Name';
export const PASSWORD = 'Password';
export const FORGOT_PASSWORD = 'Forget Password/ Password Reset';
export const USERID_COUNT = 3;
export const PASSWORD_COUNT = 3;
export const DEFAULT_USERID = 'zerone123';
export const DEFAULT_PASSWORD = 'zerone123';
export const WRONG_PASSWORD = 'The password you have entered is not correct. Please try again.';
export const WRONG_USERNAME = ' user name is incorrect';

export const DEFAULT_MIN_PASSWORD_LENGTH = 8;
export const DEFAULT_MAX_PASSWORD_LENGTH = 15;
export const NEW_PASSWORD = 'New Password';
export const CONFIRM_PASSWORD = 'Confirm Password';
export const ENTER_YOUR_VERIFIED_EMAIL = 'Enter email your verified email address';
export const COMPANYNAMEERROR = 'companyname  cannot be empty';
export const ADDCOMPANY = 'Add Company';
export const PLEASECONFIRM = 'Please confirm';
export const ENTERCOMPANYNAME = 'enter your companyname';
export const COMPANYNAME = 'Company Name';
export const COMPANYDETAILSPLACEHOLDER = 'enter company details';
export const COMPANYDETAILS = 'Company Details';
export const SAVE = 'Save';
export const CARDTITLE = 'Tenant Information';
export const NODATA = 'No Data';
export const KEEPSIGNIN = 'Keep me sign in';
export const LOGOUT = 'logout';
export const submit = 'Submit';
export const SETTINGS_HEADING = 'SETTINGS';
export const PASSWORD_SECTION = 'Password';
export const FREQ_PASSWORD_VERIFICATION = 'Allow Password Verification';
export const PASSWORD_COMPLEXITY = 'Password Complexity';
export const PASSWORD_EXPIRY = 'Password Expiry';
export const PASSWORD_MIN_LENGTH = 'Password Min. Length';
export const PASSWORD_NUMERIC = 'One Numeric';
export const PASSWORD_SPECIAL_CHAR = 'One Special Char';
export const PASSWORD_CAPITAL_LETTER = 'One Capital Letter';
export const PASSWORD_EXPIRY_NOTIFICATION = 'Enable Password Expiry Notification';
export const YES = 'Yes';
export const NO = 'No';
export const FORCE_USER_TO_CHANGE_PASSWORD = 'Force User To Change Password';
export const NO_OF_ATTEMPTS_TO_LOCK_USER = 'Select No. of Allowed Login Atempts(Wrong Attempt)';
export const ALLOW_PASSWORD_CHANGE = 'Allow User To Change Password';
export const AUTO_LOCKOUT_AFTER_IDLE_TIME = 'Auto Lockout After Idle Time Out';
export const AUTO_LOCKOUT_TIME = 'Select Auto Lockout Time';
export const ENABLE_AUTO_BACKUP = 'Enable Auto Backup';
export const SELECT_BACKUP_PATH = 'Select Backup Path';
export const SHOW_NO_FREE_SPACE_ALARM = 'Show No Free Space Alarm';
export const ENABLE_USER_DELETION = 'Enable User Deletion';
export const ALLOW_ADMIN_TO_CHANGE_USER_PASSWORD = 'Allow Admin To Reset User Password';
export const CHOOSE_DATE_FORMAT = 'Choose Date Format';
export const ENABLE_24HR = 'Enable 24 Hr Format';
export const SAVE_SETTINGS = 'Save Settings';
export const USER = 'User';
export const PASSWORD_VERIFICATION = true;
export const WRONGATTEMPT = 'Locked';
export const INVALIDPASSWORD = 'Invalid Password';
export const REMARK = 'Remark';
export const CANCEL = 'Cancel';
export const SUBMIT = 'Submit';
export const IDLETIMEOUT = 6000000;
export const PROFILE = 'Profile';
export const RESET_PASSWORD = 'Reset Password';
export const SETTINGS = 'Settings';
export const LOG_OUT = 'Log Out';
export const MIN_LENGTH = 5;
export const MAX_LENGTH = 50;
export const EMAIL_REGEX = /\S+@\S+\.\S+/;
export const ALPHANUMERIC_REGEX = /^[A-Za-z\d\-_\s]+$/;
export const STRING_REGEX = /^[A-Za-z]+$/;
export const ATLEAST_STRING_REGEX = /[a-zA-Z]/;
export const URL_REGEX =
	/https?:\/\/(?:w{1,3}\.)?[^\s.]+(?:\.[a-z]+)*(?::\d+)?(?![^<]*(?:<\/\w+>|\/?>))/;
export const DATE_REGEX = /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/]\d{4}$/;
// export const URL_REGEX =
// 	// eslint-disable-next-line no-useless-escape
// 	/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
export const USER_ADD_TITLE = 'Create user';
export const USER_UPDATE_TITLE = 'Update user';
export const USER_FIRST_NAME = 'First Name';
export const USER_MIDDLE_NAME = 'Middle Name';
export const USER_LAST_NAME = 'Last Name';
export const USER_EMAIL = 'Email';
export const USER_ID = 'User Id';
export const USER_ROLE = 'User role';
export const USER_LOCATION = 'User location';
export const testing = 'testing';

export const INACTIVE_FOR_A_WHILE = 'you are inactive for a while';

export const AUTH_TOKEN_NAME = 'token';
export const AUTH_REFRESH_TOKEN_NAME = 'refreshToken';
export const NO_PERMISSION = "You don't have permisson";

export const DELETE_CONFIRMATION = 'Are you sure want to Delete?';
export const EDIT_CONFIRMATION = 'Are you sure want to Edit?';
export const OK = 'Ok';
export const PASSWORD_EXPIRY_NOTI_IN = 'Password Expiry Notification In';
export const NUMBER_OF_ALLOWED_ATTEMPT = 'Select No. of Allowed Login Atempts(Wrong Attempt)';
export const PRODUCT_GROUP_NAME_LIMIT = 150;
export const PRODUCT_GROUP_DESCRIPTION_LIMIT = 150;
export const MANUFACTURER_NAME_LIMIT = 50;
export const MANUFACTURER_ADDRESS_LIMIT = 250;
export const MANUFACTURER_INFO_LIMIT = 250;
export const MANUFACTURER_NOTES_LIMIT = 250;
export const MANUFACTURER_INDUSTRY_LIMIT = 150;
export const MANUFACTURER_GS1_LIMIT = 12;
export const ALPHABETS_SPCLCHARACTERS = /^[A-Z@~`!@#$%^&*()_=+\\\\';: "\\/?>.<,-]*$/i;
export const ALPHANUMERIC_WITH_SPACE = /^[a-zA-Z0-9 ]*$/;
export const PACKAGE_LEVEL_LIMIT = 50;
export const NUMBERS_REGEX = /^\d+$/;
export const AUTO_SAVE = 5;
export const OTHER_DOSAGE_ID = 'RHJVL2NCR3FWbHNIL29mWVRjLzZBQT09';
export const DECIMAL_REGEX = /^\d+(\.\d+)?$/;
export const SFTP = 1;
export const REST_API = 2;
export const AS2 = 3;
export const STRING_REGEX_WITHOUT_SPACE = /^[a-zA-Z]*$/;
export const WEBLINK_NOT_VALID = 'Invalid weblink';
export const RE_DIGIT = /^\d+$/;
export const STRING_REGEX_WITH_SPACE = /^[a-zA-Z\s]+$/;
export const ALPHANUMERIC_WITHOUT_SPACE = /^[a-zA-Z0-9]+$/;

export const WEBLINK_VALID = 'Valid weblink';
export const SMALLGRIDPAGESIZE = 10;
export const MEDIUMGRIDPAGESIZE = 25;
export const LARGEGRIDPAGESIZE = 50;

export const RESEND_MINUTES = 2;
export const RESEND_SECONDS = 0;
export const PHONE_NUMBER = /^[0-9]{10}$/;
export const SLASH_AND_BACKSLASH_REGEX = /[\\/]/;

export const LAST_30_DAYS = 29;
export const LAST_90_DAYS = 89;

export const NOTIFICATION_COUNT_TIMER = 2;

export const PRODUCT = '1';
export const PRODUCT_ORDER = '2';
export const PRODUCT_WEBLINK = '3';
export const PO_WEBLINK = '4';
export const TOP_SCANNED_PRODUCTS_COUNT = 5;

export const EDITOR_MAX_LENGTH = 500;
export const EDITOR_TEXT_REQUIRED = 0;

export const INVALID_DATE_TXT = 'Invalid date';

export const ON_PROCESS = 'ON_PROCESS';
export const TENANTNAMELENGTH = 25;
export const COMMON_DATE_FORMAT = 'YYYY-MM-DDThh:mm:ssZ';
export const COMMON_DATE_FORMAT_API = 'YYYY-MM-DDThh:mm:ss';
export const ALPHANUMERIC_WITH_HYPHEN_SLASH = /^[A-Za-z0-9/-]+$/;

export const PASSWORD_MIN_LENGTH_DATA = ['8', '9', '10', '11', '12', '13', '14', '15', '16'];
export const PASSWORD_MAX_LENGTH_DATA = [
	'8',
	'9',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'20',
	'21',
	'22',
	'23',
	'24',
	'25',
	'26',
	'27',
	'28',
	'29',
	'30',
	'31',
	'32',
];
export const AWAITINGACTIONPAGESIZE = 12;
export const SERIAL_NUMBER_TEMPLATE_LENGTH = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
export const TEMPLATE_TYPE_SSCC_ID = 'NGRYMThXQW5PWjBRVWpnWXp2Ynk5QT09';
export const ONE_MINUTE_IN_MILLISECONDS = 60000;
export const PASSWORD_EXPIRY_DAYS_LIMIT = 365;
export const WEBLINK_GTIN_CODE = '01';
export const WEBLINK_SN_CODE = '21';
export const WEBLINK_BATCH = '10';
export const WEBLINK_EXP_DATE = '17';
export const SETTINGS_MIN_LENGTH = '0';
export const IDENTIFIER_LIMIT_MIN_LIMIT = 1;
export const IDENTIFIER_LIMIT_MAX_LIMIT = 20;

export const API_OUTPUT_RESULT_ID_100 = 100;
export const STATUS_CODE_200 = '200';
export const STATUS_CODE_400 = 400;
export const IMAGE_SUPPORT_FILES = 'svg, png, jpg, webp';
export const ELEAFLET_SUPPORT_FILES = 'pdf';
export const PRN_SUPPORT_FILES = 'prn';

export const MAX_FILE_SIZE = 2;
export const FILE_SIZE_1024 = 1024;
export const CANCELED_ERROR = 'CanceledError';
export const SSO_CRT_SUPPORT_FILES = 'crt';
export const SSO_XML_SUPPORT_FILES = 'xml';
export const ERROR = 'error';
export const WARNING = 'warning';
export const SUCCESS = 'success';
export const SETTINGS_UNIQUE_ERROR = 105;
export const L3_TRANSLATION_KEY = 'l3System';
export const END_USER_TRANSLATION_KEY = 'endUserScan';
export const WEBLINK_REPORT_GENERATED_DATE = 'generatedDate';
export const WEBLINK_REPORT_COMMISSIONED_ON = 'commissionedOn';
export const WEBLINK_REPORT_PROVIDED_TO_L3 = 'providedToL3On';
export const WEBLINK_REPORT_SHIPPED_ON = 'shippedOn';
export const SCAN_HISTORY_COUNT_NULL_VALUE = 0;
export const PARENT_COMPANY_NAME = 'Parent Company Name';
export const GRID_CREATED_DATE = 'createdDate';
export const GRID_REVIEWED_DATE = 'reviewedDate';
export const GRID_APPROVED_DATE = 'approvedDate';
export const GRID_RETURNED_DATE = 'returnedDate';
export const GRID_STATUS_FIELD = 'status';
export const PLEASE_SELECT_TEXT = 'Please select';
export const EXPIRY_DATE = 'expiryDate';
export const WEBLINK_DOWNLOAD_FILENAME = 'weblink';
export const GRID_GENERATED_DATE = 'generatedDate';
export const GRID_VERSION_DATE = 'versionedDate';

/** PO grid */
export const GRID_SOURCE_FIELD = 'source';
export const GRID_TYPE_FIELD = 'type';
export const GRID_STATUS_NAME_FIELD = 'statusName';
export const GRID_RETREIVAL_TYPE_FIELD = 'retrievalType';
export const GRID_ISACTIVE_FIELD = 'isActive';
export const GRID_MANUFACTURING_DATE_FIELD = 'manufacturingDate';
export const GRID_EXPIRY_DATE_FIELD = 'expiryDate';

export const RESULT_ID_105 = 105;
export const DARK_MODE_COLOUR_BACKGROUND = '#191F28';
export const LIGHT_MODE_COLOUR_BACKGROUND = '#FFFFFF';
export const DARK_MODE_TITLE_COLOUR = '#F5F5F5';
export const LIGHT_MODE_TITLE_COLOUR = '#445174';
export const ACCEPTED = 'Accepted';
export const DARK_MODE_ITEM_COLOR = '#373A42';
export const LIGHT_MODE_ITEM_COLOR = '#89C4F4';
export const EPCIS_MAX_FILE_SIZE = 20;
export const SCAN_COUNT = 'Scan Count';
export const MAXIMUM_SCAN_COUNT = 50;
export const MINIMUM_SCAN_COUNT = 1;
export const STATUS_CODE_500 = '500';
export const VERSION_NEEDED = 'Is product versioning needed for this?';
export const IDENTIFIER_TYPE_GLN = 'cHFVNTdrL2ZTNk9ZZUJ1bUZ1bmpUUT09';
export const MANUFACTURER_LOGO_NAME = 'manufacturerLogo.png';
export const PARTNER_DATA_TYPE = 'NGRYMThXQW5PWjBRVWpnWXp2Ynk5QT09';
export const EXCEL_SUPPORT_FILES = '.xlsx,.xls';
export const COMMON_DATE_TIME_FORMAT_API = 'YYYY-MM-DDTHH:mm:ss';
export const MAX_LENGTH_MANUFACTURING_LICENSE_PO = 30;
export const MIN_LENGTH_MANUFACTURING_LICENSE_PO = 10;
export const IMN_NUMBER = '20'; // need to change after getting confirmation for IMN number
export const ELEAFLET_MAX_FILE_SIZE = 5;
export const StartMinRange = 1;
export const StartMaxRange = 99998;
export const EndMinRange = 2;
export const EndMaxRange = 999999999;
export const ConfigMessageLength = 1000;