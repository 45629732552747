import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
    UpdateStatus: false,
    updateButtonClicked: false,
    EndUserConfigAdd: false,
    addButtonClicked: false,
    showNotification: false,
    message: '',
    notificationType: '',
    endUserConfigUpdate: false,
};

export const endUserConfigSlice = createSlice({
    name: 'endUserConfig',
    initialState,
    reducers: {
        setEndUserConfigAdd: (state, action: PayloadAction<any>) => {
            state.EndUserConfigAdd = action.payload;
        },
        setAddButtonStatus: (state, action: PayloadAction<any>) => {
            state.addButtonClicked = action.payload;
        },
        setEndUserConfigUpdate: (state, action: PayloadAction<any>) => {
            state.endUserConfigUpdate = action.payload;
        },
       
    },
});

export const {
    setEndUserConfigAdd,
    setAddButtonStatus,
    setEndUserConfigUpdate,
} = endUserConfigSlice.actions;
export const getConfigAddMessage = (state: RootState) => state.endUserConfig;

export const getEndUserConfigAdd = (state: RootState) =>
    state.endUserConfig.EndUserConfigAdd;

export const getEndUserConfigUpdate = (state: RootState) =>
    state.globalConfig.EndUserConfigUpdate;


export default endUserConfigSlice.reducer;
